<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
        <div class="title">
          观看详情
        </div>
        <div class='historyInfoWrap'>
            <div class='courseList'>
                <div
                    class='courseItem'
                    v-for="(item,index) in courseList" :key='index'
                    :class="{'activeCourseItem':activeIndex==index}"
                    @click="clickCourseItem(item,index)"
                >
                    <div class='item_minute'>{{item.duration}}</div>
                    <div class='item_title'>{{item.chapterTitle}}</div>
                </div>
            </div>
            <div class='courseItemWatchInfo'>
                <div class='courseItemTitle'>
                    <div>{{currentCourseName}}</div>
                    <div>{{currentCourseDuration}}分钟</div>
                </div>
                <div class='watchInfoTable'>
                    <el-table
                        :data="studyTrackTableData"
                        stripe
                        style="width: 100%">
                        <el-table-column
                            prop="startTime"
                            label="开始时间">
                        </el-table-column>
                        <el-table-column
                            prop="finishTime"
                            label="结束时间">
                        </el-table-column>
                        <el-table-column
                            prop="studyDuration"
                            label="时长"
                            width="180">
                            <template slot-scope="scope">
                                <div style='color:#265CE3'>{{transformTime(scope.row.studyDuration)}}</div>
                            </template>
                        </el-table-column>
                </el-table>
                </div>
            </div>
        </div>
        <emptyData v-show='courseList.length==0'></emptyData>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import emptyData from '@/components/emptyData.vue'
import navTab from '@/components/navTab.vue'
import footerCom from '@/components/footer.vue'
import headerTop from '@/components/header.vue'
export default {
  name: '',
  components: {
    emptyData,
    footerCom,
    navTab,
    headerTop
  },
  props: {},
  watch: {},
  data () {
    return {
      activeIndex: 0,
      courseList: [],
      studyTrackTableData: [],
      studentCourseId: '',
      currentCourseName: '',
      currentCourseDuration: 0,
    }
  },
  methods: {
    clickCourseItem (item, index) {
      console.log(item)
      this.activeIndex = index
      this.currentCourseName = item.chapterTitle
      this.getListChapterStudyTrack(item.id)
    },
    // 获取左侧课程
    getRecordsData () {
      return new Promise((resolve, reject) => {
        let reqOption = {
          'studentCourseId': this.studentCourseId
        }
        this.$axios.get(this.$apiUrl.listChapters, {params: reqOption}).then(res => {
          console.log(res)
          let courseList = res.data.data
          courseList.forEach(item => {
            let dateDiff = new Date(item.endTime).getTime() - new Date(item.beginTime).getTime()
            item.duration = parseInt(dateDiff / 1000 / 60)
          })
          resolve(courseList)
        })
      })
    },
    getListChapterStudyTrack (currentCourseId) {
      let data = {
        'studentChapterId': currentCourseId // 学员课程ID
      }
      this.$axios.get(this.$apiUrl.listChapterStudyTrack, {params: data}).then(response => {
        let res = response.data
        if (res.success) {
          let resData = res.data
          this.studyTrackTableData = JSON.parse(JSON.stringify(resData))
        }
      })
    },
    transformTime (initSecond) {
      let second = '00'
      let minute = '00'
      let hour = '00'
      if (initSecond>60) {
        second = initSecond % 60
        minute = Math.floor(initSecond / 60)
        if (minute>60) {
          hour = Math.floor(minute / 60)
          minute = minute % 60
        }
      } else {
        second = initSecond
      }
      hour = (hour + '').length<2 ? `0${hour}` : hour
      minute = (minute + '').length<2 ? `0${minute}` : minute
      second = (second + '').length<2 ? `0${second}` : second
      return hour + ':' + minute + ':' + second
    },
  },
  async mounted () {
    this.studentCourseId = this.$route.query.courseId
    this.courseList = await this.getRecordsData()
    let currentCourseId = this.courseList[0].id
    this.currentCourseName = this.courseList[0].chapterTitle
    this.currentCourseDuration = this.courseList[0].duration
    this.getListChapterStudyTrack(currentCourseId)
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;
  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px 0px;
    padding-bottom: 188px;
    background-color: transparent;
    .title{
      font-size: 20px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    }
    .historyInfoWrap{
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        .courseList{
            width: 280px;
            border: 1px solid #D3D3D3;
            border-left: 3px solid #265CE3;
            border-bottom: 0px;
            .courseItem{
                padding: 20px;
                display: flex;
                align-items: center;
                flex-direction: row;
                background-color: #FAFAFA;
                border-bottom: 1px solid #D3D3D3;
                cursor: pointer;
                .item_minute{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 3px solid #265CE3;
                    text-align: center;
                    line-height: 35px;
                    font-size: 16px;
                    color: #265CE3;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                }
                .item_title{
                    flex: 1;
                    margin-left: 15px;
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #3A3A3A;
                }
            }
            .activeCourseItem{
                background-color: #FFF;
                .item_title{
                    color: #265CE3;
                }
            }
        }
        .courseItemWatchInfo{
            width: 930px;
            border: 1px solid #D3D3D3;
            padding: 20px 30px;
            .courseItemTitle{
                height: 42px;
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                width: 100%;
                background-color: #265CE3;
                padding: 0px 26px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-radius: 42px;
            }
            .watchInfoTable{
                padding: 10px 26px;
                /deep/ .el-table{
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #7C7C7C;
                }
                /deep/ .el-table th{
                    font-size: 16px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #3A3A3A;
                }
            }
        }
    }
  }
</style>
